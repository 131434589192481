import jsx from '../lib/jsx'
import { dispatchEvent } from '../lib/helpers'

addEventListener('DOMContentLoaded', () => {
	const fileSelects = document.querySelectorAll('div.file-selection')
	console.log(fileSelects)
	for (let a = 0; a < fileSelects.length; a++) {
		initFileselection(fileSelects[a])
	}
})

export default function initFileselection(element) {

	// Removing current selected file.
	const currentFile = element.querySelector('div.current-file')
	if (currentFile) {
		const removeFile = currentFile.querySelector('a.delete-button')
		removeFile.addEventListener('click', (event) => {
			// Reset all inputs.
			const inputs = element.querySelectorAll('input')
			for (let a = 0; a < inputs.length; a++) {
				inputs[a].value = ''
			}

			currentFile.parentNode.removeChild(currentFile)
			dispatchEvent(element, 'delete')
		})

		element.currentFile = element.querySelector('a.file').href
	}

	// Tabs logic.
	const tabs = element.querySelectorAll('a.tab')
	const tabsContent = element.querySelectorAll('div.tabs-content > div')

	for (let a = 0; a < tabs.length; a++) {
		tabs[a].addEventListener('click', function (event) {
			for (let b = 0; b < tabs.length; b++) {
				// Set selected state.
				const fnName = tabs[b] === this ? 'add' : 'remove'
				tabs[b].classList[fnName]('selected')

				// Hide/show the associated tab content.
				tabsContent[b].classList[fnName]('visible')
			}
		})
	}

	// On input change.
	const fileInput = element.querySelector('input[type=file]')
	fileInput.addEventListener('change', function (event) {
		element.currentFile = event.target.files[0] || ''
	})
}